import { ApolloError, gql } from '@apollo/client';
import { UNAUTHENTICATED } from '../Typings';

export const Queries = {
  GET_CARRIER: gql`
query GetCarrierQuery($id: ID!) {
  getCarrier(id: $id) {
    id
    name
    description
    dotNumber
    ou
    photoUrl
    postalCode
    city
    state
    street1
    street2
    profile
    bannerPhoto
  }
}
`,
  GET_CARRIER_ANON: gql`
query GetCarrierAnonQuery($id: ID!) {
  getCarrierAnon(id: $id) {
    id
    name
    description
    dotNumber
    ou
    photoUrl
    postalCode
    city
    state
    street1
    street2
    profile
    bannerPhoto
  }
}
`,
  GET_CARRIER_DATA_BY_DOT: gql`
query GetCarrierDataByDotQuery($dotNumber: String!) {
  fmcsa(dotNumber: $dotNumber) {
    dotNumber
    name
    legalName
    street1
    city
    state
    postalCode
    totalDrivers
    safetyRating
    allowedToOperate
    existingCarriers {
      id
      name
      description
      dotNumber
      ou
      photoUrl
      postalCode
      city
      state
      street1
      street2
    }
  }
}
`,
  GET_MY_CARRIERS: gql`
query GetMyCarriersQuery {
  getMyCarriers {
    id
    dotNumber
    name
    description
    ou
    street1
    street2
    city
    state
    postalCode
    url
    photoUrl
    profile
    bannerPhoto
  }
}
`,
  GET_CARRIER_JOBS: gql`
query GetCarrierJobsQuery($carrierId: ID!) {
  getCarrierJobs(carrierId: $carrierId) {
    id
    title
    internalTitle
    internalReference
    description
    requirements
    jobInterests {
      id
      driver {
        id
        lastName
        firstName
        cityRegion
        photoUrl
        bannerPhoto
        profile
      }
      driverIsMessageable
      conversation { id }
      status
      displayStatus
      activeStatus
      matchScore {
        score
        label
        color
      }
    }
    publicationStatus
    publishedAt
    createdAt
    favoritesByDate { date, count }
    viewsByDate { date, anonymousViews, knownViews }
    numCandidates
    numUnreadMessagesForCarrier
    numFavorites
    lastUpdatedAt
  }
}
`,
  GET_CARRIER_JOBS_ANON: gql`
query GetCarrierJobsAnonQuery($carrierId: ID!) {
  getCarrierJobsAnon(carrierId: $carrierId) {
    id
    title
    internalTitle
    internalReference
    description
    requirements
    publicationStatus
    publishedAt
    createdAt
    lastUpdatedAt
  }
}
`,
  GET_JOB: gql`
query GetJobQuery($id: ID!) {
  getJob(id: $id) {
    id
    title
    internalTitle
    description
    requirements
  }
}`,
  GET_JOB_ANON: gql`
query GetJobAnonQuery($id: ID!) {
  getJobAnon(id: $id) {
    id
    title
    description
    requirements
    publicationStatus
    publishedAt
    createdAt
    lastUpdatedAt
    carrier {
      id
      name
      photoUrl
      street1
      street2
      city
      state
      postalCode
      description
      profile
      bannerPhoto
    }
  }
}
`,
  GET_DRIVER_ANON: gql`
query GetDriverAnonQuery($id: ID!) {
  getDriverAnon(id: $id) {
    id
    photoUrl
    bannerPhoto
    profile
  }
}
`,
  GET_JOB_CONVERSATIONS: gql`
query GetJobConversationsQuery($jobId: ID!) {
  carrierJobConversations(jobId: $jobId) {
    id
    job {
      id
      title
      internalTitle
    }
    driver {
      id
      lastName
      firstName
      cityRegion
      photoUrl
      bannerPhoto
      profile
    }
    driverIsMessageable
    jobInterest {
      id
      status
      displayStatus
      matchScore {
        score
        label
        color
      }
    }
    lastMessage {
      id
      from { id }
      to { id }
      createdAt
      message
      readAt
    }
    matchScore {
      score
      label
      color
    }
  }
}
`, 
  GET_QUESTIONNAIRES: gql`
query getQuestionnairesQuery {
  carrier: getQuestionnaire(id: CARRIER) {
    questionnaire
  }
  driver: getQuestionnaire(id: DRIVER) {
    questionnaire
  }
  job: getQuestionnaire(id: JOB) {
    questionnaire
  }
}
`, 
  GET_PUBLISHED_JOBS: gql`
query getPublishedJobsQuery {
  publishedJobs {
    id
    lastUpdatedAt
  }
}
`, 
  GET_CLOUDINARY_SIGNATURE: gql`
query getCloudinarySignatureQuery ($upload_preset: String, $tags: String) {
  signCloudinary(upload_preset: $upload_preset, tags: $tags) {
    signature
    timestamp
  }
}
`
};

export const Mutations = {
  CREATE_CARRIER: gql`
mutation CreateCarrierMutation($dotNumber: String!, $name: String!, $ou: String, $description: String, $street1: String, $street2: String, $city: String, $state: String, $postalCode: PostalCode!, $photoUrl: String, $bannerPhoto: String) {
  createCarrier(dotNumber: $dotNumber, name: $name, ou: $ou, description: $description, street1: $street1, street2: $street2, city: $city, state: $state, postalCode: $postalCode, photoUrl: $photoUrl, bannerPhoto: $bannerPhoto) {
    id
    dotNumber
    name
    ou
    description
    street1
    street2
    city
    state
    postalCode
    url
    profile
    photoUrl
    bannerPhoto
  }
}
`,
  UPDATE_CARRIER: gql`
mutation UpdateCarrierMutation($id: ID!, $dotNumber: String!, $name: String!, $ou: String, $description: String, $street1: String, $street2: String, $city: String, $state: String, $postalCode: PostalCode!, $photoUrl: String, $bannerPhoto: String) {
  updateCarrier(id: $id, dotNumber: $dotNumber, name: $name, ou: $ou, description: $description, street1: $street1, street2: $street2, city: $city, state: $state, postalCode: $postalCode, photoUrl: $photoUrl, bannerPhoto: $bannerPhoto) {
    id
    dotNumber
    name
    ou
    description
    street1
    street2
    city
    state
    postalCode
    url
    profile
    photoUrl
    bannerPhoto
  }
}
`,
  CREATE_CARRIER_USER: gql`
mutation CreateCarrierUserMutation($firstName: String!, $lastName: String!, $email: EmailAddress!, $password: String!, $photoUrl: String) {
  createCarrierUser(firstName: $firstName, lastName: $lastName, email: $email, password: $password, photoUrl: $photoUrl)
}
`,
  CREATE_CARRIER_USER_WITH_GOOGLE: gql`
mutation CreateCarrierUserWithGoogleMutation($credential: String!) {
  createCarrierUserWithGoogle(credential: $credential) {
    id
    firstName
    lastName
    email
    photoUrl
    carrierRoles {
      carrier {
        id
      }
      role
    }
    tokens {
      accessToken
      refreshToken
      expiresAt
    }
  }
}
`,
  CREATE_JOB: gql`
mutation CreateJobMutation($carrierId: ID!, $title: String!, $internalTitle: String, $internalReference: String, $description: String) {
  createJob(carrierId: $carrierId, title: $title, internalTitle: $internalTitle, internalReference: $internalReference, description: $description) {
    id
    title
    internalTitle
    internalReference
    description
    requirements
  }
}
`,
  CLONE_JOB: gql`
mutation CloneJobMutation($jobId: ID!) {
  cloneJob(jobId: $jobId) {
    id
    title
    internalTitle
    internalReference
    description
    requirements
  }
}
`,
  FIND_OR_CREATE_CONVERSATION_WITH_DRIVER: gql`
mutation FindOrCreateConversationWithDriverMutation($jobId: ID!, $driverId: ID!) {
  findOrCreateConversationWithDriver(jobId: $jobId, driverId: $driverId) {
    id
  }
}
`,
  UPDATE_CARRIER_USER: gql`
mutation UpdateCarrierUserMutation($firstName: String!, $lastName: String!, $photoUrl: String) {
  updateCarrierUser(firstName: $firstName, lastName: $lastName, photoUrl: $photoUrl) {
    id
    firstName
    lastName
    email
    photoUrl
  }
}
`,
  UPDATE_JOB: gql`
mutation UpdateJobMutation($id: ID!, $title: String!, $internalTitle: String, $internalReference: String, $description: String) {
  updateJob(id: $id, title: $title, internalTitle: $internalTitle, internalReference: $internalReference, description: $description) {
    id
    title
    internalTitle
    internalReference
    description
    requirements
  }
}
`,
  LOGIN: gql`
mutation LoginMutation($email: EmailAddress!, $password: String!) {
  login(email: $email, password: $password) {
    id
    firstName
    lastName
    email
    photoUrl
    carrierRoles {
      carrier {
        id
      }
      role
    }
    tokens {
      accessToken
      refreshToken
      expiresAt
    }
  }
}
`,
  LOGIN_WITH_GOOGLE: gql`
mutation LoginWithGoogleMutation($credential: String!) {
  loginWithGoogle(credential: $credential) {
    id
    firstName
    lastName
    email
    photoUrl
    carrierRoles {
      carrier {
        id
      }
      role
    }
    tokens {
      accessToken
      refreshToken
      expiresAt
    }
  }
}
`,
  LOGOUT: gql`
mutation LogoutMutation {
  logout
}
`,
  UPDATE_DRIVER_MESSAGES_AND_FETCH_CONVERSATION: gql`
mutation UpdateDriverMessagesAndFetchConversationMutation($conversationId: ID!) {
  updateDriverMessagesAndFetchConversation(conversationId: $conversationId) {
    id
    messages { 
      id 
      from {
        id
        firstName
        lastName
        photoUrl
      }
      to {
        id
        firstName
        lastName
      }
      message
      createdAt
      readAt
    }
  }
}
`,
  RESET_PASSWORD: gql`
mutation ResetPasswordMutation($email: EmailAddress!, $newPassword: String!) {
  resetPassword(email: $email, newPassword: $newPassword)
}
`,
  SEND_PASSWORD_RECOVERY_EMAIL: gql`
mutation SendPasswordRecoveryEmailMutation($email: EmailAddress!) {
  sendPasswordRecoveryEmail(email: $email)
}
`,
  UPDATE_JOB_PUBLICATION_STATUS: gql`
mutation UpdateJobPublicationStatusMutation($id: ID!, $publicationStatus: PublicationStatusEnum!) {
  updateJobPublicationStatus(id: $id, publicationStatus: $publicationStatus) {
    id
  }
}
`,
  ARCHIVE_JOB_AND_SEND_MESSAGE_TO_REMAINING_APPLICANTS: gql`
mutation ArchiveJobAndSendMessageToRemainingApplicantsMutation($id: ID!, $message: String) {
  archiveJobAndSendMessageToRemainingApplicants(id: $id, message: $message) {
    id
  }
}
`,
  UPDATE_JOB_INTEREST_STATUS_AND_SEND_MESSAGE_TO_DRIVER: gql`
mutation UpdateJobInterestStatusAndSendMessageToDriverMutation($jobInterestId: ID!, $status: JobInterestEnum!, $message: String!) {
  updateJobInterestStatusAndSendMessageToDriver(jobInterestId: $jobInterestId, status: $status, message: $message) {
    id
  }
}
`,
  UPDATE_QUESTIONNAIRE_ANSWERS: gql`
mutation UpdateQuestionnaireAnswersMutation($questionnaireId: QuestionnaireEnum!, $targetId: ID!, $answerData: JSON!) {
  updateQuestionnaireAnswers(questionnaireId: $questionnaireId, targetId: $targetId, answerData: $answerData)
}
`,
  SEND_MESSAGE: gql`
mutation SendMessageMutation($jobId: ID!, $driverId: ID!, $message: String!) {
  newMessageFromCarrierUser(jobId: $jobId, driverId: $driverId, message: $message) {
    id
  }
}
`,
  VALIDATE_EMAIL: gql`
mutation ValidateEmailMutation($emailId: ID!, $email: EmailAddress!) {
  validateEmail(id: $emailId, email: $email) {
    id
    firstName
    lastName
    email
    photoUrl
    carrierRoles {
      role
    }
    tokens {
      accessToken
      refreshToken
      expiresAt
    }
  }
}
`,
  VALIDATE_DRIVER_EMAIL: gql`
mutation validateDriverEmailMutation($id: ID!, $email: EmailAddress!) {
  validateEmail(id: $id, email: $email) {
    id
    email
    tokens {
      accessToken
      refreshToken
    }
  }
}
`
};

export const getErrorMessage = (e: ApolloError, excludeTimestamp = false) => {
  console.error(e);

  if (e.networkError || e.message === 'Failed to fetch') {
    return 'Server is unavailable.  Please check your internet connection.';
  }
  
  let errorMessage: string | undefined;
  const graphQLErrors = e.graphQLErrors;
  if (graphQLErrors && graphQLErrors.length && graphQLErrors[0].extensions) {
    const extensions = graphQLErrors[0].extensions;
    if (extensions) {
      if (extensions.code === UNAUTHENTICATED) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('userInfo');
    
        return UNAUTHENTICATED;
      }
      
      const detail: string = extensions.exception.detail;
      if (detail && detail.indexOf('already exists') > 0)
        return 'duplicate';

      errorMessage = detail;
    }
  }

  if (!errorMessage) errorMessage = e.message || 'Oops, something went wrong! Please try again.';

  if (excludeTimestamp) return errorMessage;

  const dateStr = new Date().toUTCString();
  return `${errorMessage} (${dateStr.substring(5).replace('GMT', 'Z')})`;
};