import { useReducer, MouseEvent, HTMLAttributes } from 'react';
import { ReactComponent as ChevronSvg } from '../../../assets/Chevron.svg'
import { IJobCandidate } from '../../../Typings'
import { useQuestionnaire } from '../../../context/QuestionnaireContext';
import { PersonAvatar } from '../cloudinary/PersonAvatar'
import { useJobs } from '../../../context/JobsContext';
import Divider from '../../common/Divider';
import MatchRibbon from './MatchRibbon';
import ReadOnlyMapAnswer from '../questionnaire/ReadOnlyMapAnswer';
import './DriverProfile.css';
import { BannerImage } from '../cloudinary/BannerImage';

export interface IDriverProfileProps extends HTMLAttributes<HTMLDivElement> {
  model: IJobCandidate;
};

type DriverProfileSectionExpandedAction = {
  index: number;
  value: boolean;
};

const DriverProfile = ({ model }: IDriverProfileProps) => {
  const questionnaireService = useQuestionnaire();
  const jobCandidate = model as IJobCandidate;
  const { activeJob } = useJobs();

  const reducer = (selection: Array<boolean>, action: DriverProfileSectionExpandedAction) => {
    const newSelection: Array<boolean> = [...selection];
    newSelection[action.index] = action.value;

    return newSelection;
  };

  const getSectionExpanded = () => new Array(questionnaireService.driverQuestionnaire?.json.sections.length).fill(true); // This doesn't really work because questionnaireService.driverQuestionnaire?.json.sections.length is always undefined here
  const [ sectionExpanded, sectionExpandedDispatch ] = useReducer(reducer, getSectionExpanded());

  const handleDropDownClick = (e: MouseEvent<HTMLButtonElement>) => {
    const indexStr = e.currentTarget.getAttribute('data-index');
    if (indexStr) {
      const index = +indexStr;
      sectionExpandedDispatch({ index: index, value: !sectionExpanded[index] });
    }
    e.stopPropagation();
  };

  const getDropDownContentClass = (index: number) => `driver-profile-drop-down-content${sectionExpanded[index] ? '' : ' content-closed'} fs-14`;
  const getDropDownBtnSvgClass = (index: number) => `caret-${sectionExpanded[index] ? 'up' : 'down'}`;

  const composeSection = (questions: any) =>
    questions.map((question: any, index: number) => {
      let element: JSX.Element | undefined;
      let answers: string = '';

      if (question.type === 'map') {
        const answersArray = questionnaireService.driverQuestionnaire?.getMultipleAnswerIdArray(question.id, jobCandidate.driver.profile) ?? [];
        element = <ReadOnlyMapAnswer className='driver-profile-map-answer' answers={answersArray} choicesJson={question.choices} />
      } else {
        switch (question.type) {
          case 'boolean':
          case 'single':
            answers = questionnaireService.driverQuestionnaire?.getSingleAnswerText(question.id, jobCandidate.driver.profile) ?? '';
            break;
          case 'range':
            answers = `${question.formatAsCurrency ? '$' : ''}${questionnaireService.driverQuestionnaire?.getSingleAnswerText(question.id, jobCandidate.driver.profile) ?? ''}`;
            break;   
          case 'multiple':
            answers = questionnaireService.driverQuestionnaire?.getMultipleAnswerTextCsv(question.id, jobCandidate.driver.profile) ?? '';
            break;
        }
        element = answers?.length
          ? activeJob && (Math.random() > 1.0)
            ? <p className='medium-gray fw-n ls-1' style={{color: 'red'}}>{answers}</p>
            : <p className='medium-gray fw-n ls-1'>{answers}</p>
          : undefined;
      }

      if (!element) return;

      return (
        <div key={`question${index}_div`} className='driver-profile-drop-down-qa'>
          <p className='fw-5 ls-2'>{question.profileTitle}</p>
          {element}
        </div>
      );
    });

  const composeSections = () => {
    return Object.values(questionnaireService.driverQuestionnaire?.json.sections)
      .filter((section: any) => section.questions?.length > 0)
      .map((section: any, index) => {
        // This hack works around the fact that the initialization of sectionExpanded above doesn't work which is due to the fact that questionnaireService.driverQuestionnaire?.json.sections.length is undefined when sectionExpanded is initialized
        if (sectionExpanded.length <= index) {
          sectionExpandedDispatch({ index: index, value: true });
        }
        
        return (
          <div key={section.name} className='driver-profile-drop-down off-black'>
            <div className='driver-profile-drop-down-header'>
              <div className='driver-profile-drop-down-my-story fs-16 ls-2'>
                <span>{section.name}</span>
              </div>
              <button className='driver-profile-drop-down-btn' data-index={index} onClick={handleDropDownClick}>
                <ChevronSvg className={getDropDownBtnSvgClass(index)}/>
              </button>
            </div>
            <div className={getDropDownContentClass(index)}>
              {composeSection(section.questions)}
            </div>
          </div>
        );
      });
  };

  return (
    <div className='driver-profile off-black fw-5'>
      <BannerImage publicId={jobCandidate.driver.bannerPhoto} className='banner-image' />
      <div className='driver-profile-content'>
        <div>
          <PersonAvatar publicId={jobCandidate.driver.photoUrl} size={91} className='driver-foto' />
          { jobCandidate.matchScore && <MatchRibbon matchScore={jobCandidate.matchScore} className='profile-match-ribbon' /> }
        </div>
        <div className='driver-profile-header-text fw-4 fs-14'>
          {
            (jobCandidate.driver.lastName || jobCandidate.driver.firstName) && 
              <p key='driverName_p' >
                {`${jobCandidate.driver.firstName ?? ''}${jobCandidate.driver.firstName ? `${(jobCandidate.driver.firstName && jobCandidate.driver.lastName) ? ' ' : ''} ${jobCandidate.driver.lastName}` : ''}`}
              </p>
          }
          { 
            jobCandidate.driver.cityRegion && 
              <p key='driverCityState_p' style={{ color: 'var(--medium-gray)' }}>
                <i>{`${jobCandidate.driver.cityRegion}`}</i>
              </p>
          }
        </div>
      </div>
      <Divider className='driver-profile-divider' style={{ marginTop: '20px'}} />
      {jobCandidate && composeSections()}
    </div>
  )
};

export default DriverProfile;