import { useParams, useHistory, NavLink } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { getErrorMessage, Queries } from '../../util/Graphql';
import { useQuestionnaire } from '../../context/QuestionnaireContext';
import { timeAgo } from '../../util/TimeAgo';
import Navbar, { NavLogo } from '../common/Navbar';
import TruckerFyLogo, { TruckerFyLogoTheme } from '../common/TruckerFyLogo';
import NavbarLayout from '../layouts/NavbarLayout';
import { ReactComponent as AppStoreBadgeSvg } from '../../assets/AppStoreBadge.svg';
import { BannerImage } from '../specialized/cloudinary/BannerImage';
import { CarrierLogo } from '../specialized/cloudinary/CarrierLogo';
import Divider from '../common/Divider';
import GoogePlayBadgePng from '../../assets/GooglePlayBadge.png';
import './CarrierJobs.css';
import { Carrier, Job } from '../../Typings';
import { useEffect, useState } from 'react';
import { isUuid } from '../../util/ObjectUtil';
import Loader from '../transition/Loader';
import { CdlClassSvg, IncomeSvg, WorkRouteSvg, DaysOutSvg, SoloSvg, TeamSvg, PetsOkSvg, NoPetsSvg, EndorsementsSvg } from '../../assets/vector-icons';
import { CarrierLdJson } from './CarrierLdJson';

const CarrierJobs = () => {
  const questionnaireService = useQuestionnaire();
  const history = useHistory();
  const { carrierId } = useParams<{ carrierId: string }>();
  const [ carrier, setCarrier ] = useState<Carrier>();
  const [ carrierJobs, setCarrierJobs ] = useState<Job[]>();

  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'TruckerFy - View Carrier Jobs';

    return () => { document.title = prevTitle; };
  }, []);

  const handleCarrierDataFetch = (data: any) => {
    if (data?.getCarrierAnon) {
      setCarrier(data.getCarrierAnon);
    } else {
      history.push('/notfound');
    }
  };

  const handleCarrierJobsDataFetch = (data: any) => {
    if (data?.getCarrierJobsAnon) {
      setCarrierJobs(data.getCarrierJobsAnon);
    } else {
      history.push('/notfound');
    }
  };

  const [getCarrierFunction, { loading: carrierLoading }] = useLazyQuery(
    Queries.GET_CARRIER_ANON, 
    { 
      fetchPolicy: 'no-cache', 
      variables: { id: carrierId },
      onError(e) {
        const errorMsg = getErrorMessage(e);
        history.push('/oops', { error: errorMsg });
      },
      onCompleted(data) {
        handleCarrierDataFetch(data); 
      } 
    }
  );

  const [getCarrierJobsFunction, { loading: carrierJobsLoading }] = useLazyQuery(
    Queries.GET_CARRIER_JOBS_ANON, 
    { 
      fetchPolicy: 'no-cache', 
      variables: { carrierId },
      onError(e) {
        const errorMsg = getErrorMessage(e);
        history.push('/oops', { error: errorMsg });
      },
      onCompleted(data) {
        handleCarrierJobsDataFetch(data); 
      } 
    }
  );

  useEffect(() => {
    if (isUuid(carrierId)) {
      getCarrierFunction();
      getCarrierJobsFunction();
    }
    else
      history.push('/notfound');
  }, [carrierId, getCarrierFunction, getCarrierJobsFunction, history]);

  const composeHeader = () => (
    <div className='carrier-jobs-post-header'>
      <BannerImage publicId={carrier?.bannerPhoto} className='carrier-jobs-post-header-carrier-banner' />
      <div className='carrier-jobs-post-header-banner-match'>
        {carrier?.photoUrl && <CarrierLogo size={91} publicId={carrier?.photoUrl} className='carrier-jobs-post-header-carrier-logo' />}
      </div>
      <div key='tile_div' className='carrier-jobs-post-header-text fw-4 fs-14'>
        <p key='carrierName_p'>
          <span key='carrierName_s' className='fw-b fs-18'>{carrier?.name}</span>
          <span style={{ display: 'inline-block', width: '10px' }}></span>
          <span key='carrierDot_s'>( DOT#: {carrier?.dotNumber} )</span>
        </p>
        {/* <p key='carrierName_p' className='fw-b fs-18'>{carrier?.name} (DOT#: {carrier?.dotNumber})</p> */}
        {/* <p key='carrierDot_p'>DOT#: {carrier?.dotNumber}</p> */}
        {
          (carrier?.city || carrier?.state) && 
            <p key='carrierCityState_p' style={{ color: 'var(--medium-gray)' }}>
              <i>{`${carrier?.city ?? ''}${carrier?.state ? `${(carrier?.city && carrier?.state) ? ', ' : ''} ${carrier?.state}` : ''}`}</i>
            </p>
        }
        {
          carrier?.description &&
            <p key='carrierDescription_p'>{carrier.description}</p>
        }
        {
          carrier?.profile &&
            <p><strong>Benefits:</strong>&nbsp;{questionnaireService.carrierQuestionnaire?.getMultipleAnswerTextCsv('benefits', carrier.profile, 'None')}</p>
        }
        {
          carrier?.profile &&
            <p><strong>Amenities:</strong>&nbsp;{questionnaireService.carrierQuestionnaire?.getMultipleAnswerTextCsv('amenities', carrier.profile, 'None')}</p>
        }
      </div>
    </div>
  );

  const composeCdlClassAndIncome = (job: Job) => {
    const cdlClassText = questionnaireService.jobQuestionnaire?.getSingleAnswerText('cdlClass', job.requirements, 'n/a');
    let incomeText = questionnaireService.jobQuestionnaire?.getSingleAnswerText('income', job.requirements, 'n/a');
    incomeText = incomeText === 'n/a' ? incomeText : incomeText + '+/month';
    return (
      <>
        <CdlClassSvg /><span>{cdlClassText}</span>
        <IncomeSvg /><span>{incomeText}</span>
      </>);
  };

  const composeWorkRoutesAndDaysOut = (job: Job) => {
    const workRoutesText = questionnaireService.jobQuestionnaire?.getMultipleAnswerTextCsv('workRoutes', job.requirements, 'n/a');
    const daysOutText = questionnaireService.jobQuestionnaire?.getSingleAnswerText('daysOut', job.requirements, 'n/a');
    return (
      <>
        <WorkRouteSvg width={22} /><span>{workRoutesText}</span>
        <DaysOutSvg width={22} /><span>{daysOutText}</span>
      </>
    );
  };

  const composeWorkTypeAndPets = (job: Job) => {
    const workType = questionnaireService.jobQuestionnaire?.getMultipleAnswerTextCsv('workType', job.requirements, 'n/a') ?? '';
    let workTypeText = workType;
    let workTypeIcon: React.ReactNode = <SoloSvg />;
    if (workType?.indexOf('Team') >= 0) {
      workTypeIcon = <TeamSvg />;
    }

    const petText = questionnaireService.jobQuestionnaire?.getSingleAnswerText('pets', job.requirements, 'n/a');
    let petTextResolved = petText;
    let petIcon: React.ReactNode;
    switch (petText) {
      case 'Yes':
        petTextResolved = 'Pets OK';
        petIcon = <PetsOkSvg />;
        break;
      case 'No':
        petTextResolved = 'No Pets';
        petIcon = <NoPetsSvg style={{width: '30px', height: '30px'}} />;
        break;
    }

    return (
      <>
        {workTypeIcon || <div key='workTypeIcon' />}<span>{workTypeText}</span>
        {petIcon || <div key='petIcon' />}<span>{petTextResolved}</span>
      </>);
  };

  const composeEndorsements = (job: Job) => {
    let endorsementsText = questionnaireService.jobQuestionnaire?.getMultipleAnswerTextCsv('endorsements', job.requirements, 'n/a');
    return (
      <>
        <EndorsementsSvg style={{ width: '26px', height: '26px' }} />
        <span style={{ gridColumn: 'span 3' }}>{endorsementsText}</span>
      </>
    );
  };

  const composeJobDetails = (job: Job) => {
    return (
      <>
        <p style={{ marginTop: '0', marginBottom: '0' }}>&nbsp;</p>
        <div key='carrierjobDetails_div' className='carrier-job-grid fs-14'>
          {composeCdlClassAndIncome(job)}
          {composeWorkRoutesAndDaysOut(job)}
          {composeWorkTypeAndPets(job)}
          {composeEndorsements(job)}
        </div>
      </>
    );
  };

  const composeJobs = () => {
    return carrierJobs?.map((job, index) => 
      <div key={job.id}>
        <br />
        <NavLink className='fw-5' to={`/job/${job.id}`}>{job.title}</NavLink>
        <span className='ls-3 fs-12' style={{ color: 'var(--medium-gray)', float: 'right' }}>{job.publishedAt ? timeAgo(job.publishedAt) : ''}</span>
        <br />
        {composeJobDetails(job)}
        { 
          (index < carrierJobs.length - 1) &&
            <Divider className='carrier-jobs-post-divider' style={{ marginTop: '20px'}} />
        }
      </div>
    );
  };

  return (
    <>
      { carrier && <CarrierLdJson carrier={carrier} /> }
      <NavbarLayout>
        <header className='carrier-jobs-page-header'>
          <Navbar>
            <NavLogo><TruckerFyLogo theme={TruckerFyLogoTheme.OnWhite} linkAddress='/' /></NavLogo>
          </Navbar>
        </header>
        <main className='carrier-jobs-page-main'>
          { (carrierLoading || carrierJobsLoading)  && <Loader /> }
          {
            carrier && carrierJobs &&
            <div className='carrier-jobs-page-container'>
              <div className='carrier-jobs-page-content'>
                {composeHeader()}
                <h4>Currently available driving jobs:</h4>
                <Divider className='carrier-jobs-post-divider' style={{ marginTop: '20px'}} />
                {composeJobs()}
              </div>
              <p className='off-black fs-19 fw-5'>Apply to these jobs on the TruckerFy app!</p>
              <div className='carrier-jobs-page-badge-container'>
                <AppStoreBadgeSvg title='' onClick={() => (window.location.href = process.env.REACT_APP_APPLE_STORE_URL ?? '/')} />
                <img src={GoogePlayBadgePng} alt='Google Play' onClick={() => (window.location.href = process.env.REACT_APP_GOOGLE_PLAY_URL ?? '/')} />
              </div>
            </div>
          }
        </main>
      </NavbarLayout>
    </>
  );
};

export default CarrierJobs;